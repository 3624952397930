import { chAxios } from '@/shared/lib/axios';

export async function fetchFandomIndexContent() {
  const url = `/public/fandoms/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getFandom(slug: string) {
  const url = `/public/fandoms/${slug}/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function submitFandomFavorite(fandom: string, url: string, note: string) {
  // "fandom" is a slug
  const endpoint = `/public/fandom/favorites/`;
  try {
    const { data } = await chAxios().post(endpoint, {
      fandom,
      url,
      note,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getOriginalCharacterCategoryPage(slug: string) {
  const endpoint = `/public/oc-pages/?slug=${slug}`;
  const { data } = await chAxios().get(endpoint);
  return data;
}

export async function getOriginalCharacterIndexPage() {
  const endpoint = `/public/oc/`
  const { data } = await chAxios().get(endpoint);
  return data;
}
